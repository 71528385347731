@use '~@juullabs/react-components/src/styles/atom';
@use '@juullabs/react-components/src/styles/mixins';

.wrapper {
  @include mixins.breakpoint(tablet) {
    margin-right: atom.getMargin(element, wide);
  }

  .logo {
    display: flex;
    height: 24px;
    align-items: center;
  }
}
